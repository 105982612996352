import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,8,9,10];

export const dictionary = {
		"/(home)": [~16],
		"/about-us": [~26],
		"/ama": [27],
		"/authors/[uid]": [~29],
		"/auth/callback": [28],
		"/(screeners)/best-singapore-dividend-stocks": [~17,[4]],
		"/(screeners)/best-singapore-high-dividend-stocks": [~18,[4]],
		"/(screeners)/best-singapore-reits": [~19,[4]],
		"/contact": [30],
		"/disclaimer": [~31],
		"/editorial-guidelines": [32],
		"/embed/cash-mgmt-yield": [~33,[6]],
		"/embed/compare-yield": [~34,[6]],
		"/embed/dividend-calculator/[ticker]": [~35,[6]],
		"/embed/internship-assignment": [36,[6]],
		"/embed/money-habits-tracker": [37,[6]],
		"/embed/price-to-book": [~38,[6]],
		"/embed/reit-yields": [~39,[6]],
		"/embed/savings-account-calculator": [~40,[6]],
		"/embed/srs-tax-savings-calculator": [41,[6]],
		"/embed/ssb-swap-calculator": [~42,[6]],
		"/embed/tbill-calendar": [~43,[6]],
		"/events": [~44],
		"/(unauthenticated)/forgot-password": [21,[5]],
		"/glossary": [~45],
		"/(catalog)/insights/[[theme]]": [~13,[3]],
		"/(catalog)/investing-101/[[theme]]": [~14,[3]],
		"/(unauthenticated)/login": [22,[5]],
		"/newsletter-signup": [47],
		"/newsletter": [~46],
		"/partners": [~48],
		"/privacy": [~49],
		"/promo": [~50],
		"/promo/expired": [~53],
		"/promo/[slug]": [~51,[8]],
		"/promo/[slug]/thank-you": [52,[8]],
		"/quote/[ticker]": [54,[9]],
		"/quote/[ticker]/dividend": [~55,[9]],
		"/reits": [56,[10]],
		"/reits/all": [~57],
		"/reits/compare": [~58],
		"/reits/sectors": [59,[10]],
		"/reits/sectors/[theme]": [~60],
		"/(unauthenticated)/reset-password/[email]": [23,[5]],
		"/(catalog)/reviews/[[reviewType]]": [~15,[3]],
		"/search": [61],
		"/securities/[[type=securityType]]": [~62],
		"/(unauthenticated)/signup": [24],
		"/terms-of-use": [~63],
		"/tools": [~64],
		"/tools/passive-income-calculator": [~65],
		"/tools/srs-tax-savings-calculator": [66],
		"/tools/ssb-interest-rate-projection": [~67],
		"/tools/ssb-swap-calculator": [~68],
		"/tools/tbill-calculator": [~69],
		"/(screeners)/upcoming-dividends": [~20,[4]],
		"/(unauthenticated)/verify/[payload]": [25,[5]],
		"/(authenticated)/watchlist": [12,[2]],
		"/(article)/[...slug]": [~11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';